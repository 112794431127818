import React from 'react';

function Home() {
  return (
    <div>
      Welcome to the Home Page!
    </div>
  );
}

export default Home;
